<template>
  <app-page-layout :title="performanceEvaluationResult.name" hide-icon @close="leavePage">
    <template #content>
      <user-profile :evaluatee="performanceEvaluationResult.evaluatee" />
      <validation-observer ref="form">
        <v-row no-gutters>
          <v-col cols="12" v-if="performanceEvaluationResult.kpiCategories.length">
            <v-row class="align-center">
              <v-col class="shrink">
                <v-icon class="kpiColor">mdi-star</v-icon>
              </v-col>
              <v-col class="grow">
                <div class="title kpiColor font-weight-bold">{{ $t("hr.performance.kpis") }}</div>
              </v-col>
            </v-row>
            <v-card flat v-for="(row, index) in performanceEvaluationResult.kpiCategories" class="mb-3" :key="index">
              <v-card-text class="pb-0 pt-0 px-0">
                <v-card-title class="grey lighten-5 mb-3 py-2">{{ row.name }}</v-card-title>
                <v-card-text class="py-0 px-1">
                  <v-card tile v-for="(item, key) in row.items" :key="key" class="mb-5 custom-card-shadow">
                    <v-card-title
                      class="body-2 font-weight-bold"
                      :class="item.comment || item.description ? 'pb-0 pt-4' : null"
                    >
                      <div class="title mb-2">{{ item.name }}</div>
                      <p class="font-weight-medium body-2" v-html="item.description"></p>
                    </v-card-title>

                    <v-card-text class="pt-0 pb-5 px-4">
                      <v-row>
                        <v-col cols="12" v-if="item.comment">
                          <v-subheader class="px-0 py-0 black--text font-weight-medium">Hedef Yorumu</v-subheader>
                          <p class="mb-0" v-html="item.comment"></p>
                        </v-col>
                        <v-layout row wrap justify-end>
                          <v-col cols="5" class="pb-0">
                            <validation-provider
                              name="Puan"
                              :rules="
                                `numeric|min_value:${performanceEvaluationResult.scoreSettings.minScore}|max_value:${performanceEvaluationResult.scoreSettings.maxScore}`
                              "
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                v-numeric.number
                                :name="`score_${item.id}`"
                                v-model.number="item.score"
                                :placeholder="$t('hr.performance.score')"
                                type="number"
                                outlined
                                label="Puan giriniz"
                                :error-messages="errors"
                                :suffix="`/ ${performanceEvaluationResult.scoreSettings.maxScore}`"
                                :min="performanceEvaluationResult.scoreSettings.minScore"
                                :max="performanceEvaluationResult.scoreSettings.maxScore"
                              >
                                <template v-slot:append>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-icon color="blue" dark v-on="on">mdi-information</v-icon>
                                    </template>
                                    <span>
                                      <div class="body-2 font-weight-bold">
                                        Puan Tablosu
                                      </div>
                                      <v-divider dark></v-divider>
                                      <div
                                        v-for="item in performanceEvaluationResult.scoreSettings
                                          .scoreSettingsDescriptions"
                                      >
                                        {{ item.fromScore }} - {{ item.toScore }} /
                                        {{ item.description }}
                                      </div>
                                    </span>
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" class="pt-0 mt-0">
                            <div class="caption">
                              (Bu performans maddesinin ağırlığı
                              <b>%{{ item.weight }}</b> 'dir. Vereceğiniz puanın <b>%{{ item.weight }}</b> oranı kadar
                              performans sonucuna etki edecektir.)
                            </div>
                          </v-col>
                        </v-layout>
                        <v-col cols="12" class="py-0">
                          <v-subheader class="pl-0">{{ $t("global.comment") }}</v-subheader>
                          <validation-provider :name="`comment_${item.id}`" :rules="`max:2048`" v-slot="{ errors }">
                            <v-textarea
                              :name="`comment_${item.id}`"
                              v-model.trim="item.userComment"
                              single-line
                              placeholder="Yorum ekleyebilirsiniz"
                              :error-messages="errors"
                              :counter="512"
                              outlined
                              clearable
                              no-resize
                              color="grey"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-col cols="12" v-if="performanceEvaluationResult.competenceCategories.length" mt-10 class="px-0">
          <v-row class="align-center">
            <v-col class="shrink">
              <v-icon class="competenceColor">mdi-star</v-icon>
            </v-col>
            <v-col class="grow">
              <div class="title competenceColor font-weight-bold">
                {{ $t("hr.performance.competences") }}
              </div>
            </v-col>
          </v-row>
          <v-card
            flat
            v-for="(row, index) in performanceEvaluationResult.competenceCategories"
            class="mb-3"
            :key="index"
          >
            <v-card-text class="pb-0 pt-0 px-0">
              <v-card-title class="grey lighten-5 mb-3 py-2">{{ row.name }}</v-card-title>
              <v-card-text class="py-0 px-1">
                <v-card tile v-for="(item, key) in row.items" :key="key" class="mb-5 custom-card-shadow">
                  <v-card-title
                    class="body-2 font-weight-bold"
                    :class="item.comment || item.description ? 'pb-0 pt-4' : null"
                  >
                    <div class="title mb-2">{{ item.name }}</div>
                    <p class="font-weight-medium body-2" v-html="item.description"></p>
                  </v-card-title>

                  <v-card-text class="pt-0 pb-5 px-4">
                    <v-row>
                      <v-col cols="12" v-if="item.comment">
                        <v-subheader class="px-0 py-0 black--text font-weight-medium">Yetkinlik Yorumu</v-subheader>
                        <p class="mb-0" v-html="item.comment"></p>
                      </v-col>
                      <v-layout row wrap justify-end>
                        <v-col cols="5" class="pb-0">
                          <validation-provider
                            :name="`score_${item.id}`"
                            :rules="
                              `numeric|min_value:${performanceEvaluationResult.scoreSettings.minScore}|max_value:${performanceEvaluationResult.scoreSettings.maxScore}`
                            "
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              :name="`score_${item.id}`"
                              v-model.number="item.score"
                              :placeholder="$t('hr.performance.score')"
                              type="number"
                              outlined
                              label="Puan giriniz"
                              :error-messages="errors"
                              :suffix="`/ ${performanceEvaluationResult.scoreSettings.maxScore}`"
                              :min="performanceEvaluationResult.scoreSettings.minScore"
                              :max="performanceEvaluationResult.scoreSettings.maxScore"
                            >
                              <template v-slot:append>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-icon color="blue" dark v-on="on">mdi-information</v-icon>
                                  </template>
                                  <span>
                                    <div class="body-2 font-weight-bold">
                                      Puan Tablosu
                                    </div>
                                    <v-divider dark></v-divider>
                                    <div
                                      v-for="item in performanceEvaluationResult.scoreSettings
                                        .scoreSettingsDescriptions"
                                    >
                                      {{ item.fromScore }} - {{ item.toScore }} /
                                      {{ item.description }}
                                    </div>
                                  </span>
                                </v-tooltip>
                              </template>
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" class="pt-0 mt-0">
                          <div class="caption">
                            (Bu performans maddesinin ağırlığı
                            <b>%{{ item.weight }}</b> 'dir. Vereceğiniz puanın <b>%{{ item.weight }}</b> oranı kadar
                            performans sonucuna etki edecektir.)
                          </div>
                        </v-col>
                      </v-layout>
                      <v-col cols="12" class="py-0">
                        <v-subheader class="pl-0">{{ $t("global.comment") }}</v-subheader>
                        <validation-provider :name="`comment_${item.id}`" :rules="`max:2048`" v-slot="{ errors }">
                          <v-textarea
                            :name="`comment_${item.id}`"
                            v-model.trim="item.userComment"
                            single-line
                            placeholder="Yorum ekleyebilirsiniz"
                            :error-messages="errors"
                            :counter="512"
                            outlined
                            clearable
                            no-resize
                            color="grey"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card-text>
          </v-card>
        </v-col>
      </validation-observer>
    </template>
    <template #footer>
      <app-page-footer>
        <template #right>
          <v-btn color="primary" :loading="loading" text @click.stop="validateAndSave">{{ $t("buttons.save") }}</v-btn>
        </template>
      </app-page-footer>
    </template>
  </app-page-layout>
</template>

<script>
  import { EMPLOYEE_EVALUATION_DETAIL } from "../query";
  import {
    PerformanceEvaluationResult,
    PerformanceEvaluationResultCategory,
    PerformanceEvaluationResultCategoryItem
  } from "./model/PerformanceEvaluationResult";

  export default {
    name: "PerformanceEvaluatee",
    props: {
      id: String
    },
    components: {
      AppPageFooter: () => import("@/layouts/app-page-footer"),
      "user-profile": () => import("./components/user-profile")
    },
    data: () => ({
      loading: false,
      scoreInfo: false,
      expandedKpiCategories: [],
      expandedCompetenceCategories: [],
      performanceEvaluationResult: new PerformanceEvaluationResult()
    }),
    watch: {
      id: {
        handler() {
          this.fetchSelectedItemById();
        },
        immediate: true
      }
    },
    methods: {
      leavePage() {
        this.$router.replace({ name: "performances" });
      },
      async fetchSelectedItemById() {
        this.loading = true;
        await this.$apollo
          .query({
            query: EMPLOYEE_EVALUATION_DETAIL,
            variables: {
              id: this.$helpers.decodeId(this.id)
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data: { performanceEvaluationResult }, errors }) => {
            if (!errors) {
              this.performanceEvaluationResult.id = performanceEvaluationResult.id;
              this.performanceEvaluationResult.name = performanceEvaluationResult.evaluation.name;
              this.performanceEvaluationResult.expirationBeginDate =
                performanceEvaluationResult.evaluation.expirationBeginDate;
              this.performanceEvaluationResult.expirationEndDate =
                performanceEvaluationResult.evaluation.expirationEndDate;
              this.performanceEvaluationResult.validationBeginDate =
                performanceEvaluationResult.evaluation.validationBeginDate;
              this.performanceEvaluationResult.validationEndDate =
                performanceEvaluationResult.evaluation.validationEndDate;
              this.performanceEvaluationResult.evaluatee = performanceEvaluationResult.evaluatee;
              this.performanceEvaluationResult.scoreSettings = performanceEvaluationResult.evaluation.scoreSettings;
              this.performanceEvaluationResult.competenceWeight =
                performanceEvaluationResult.evaluation.template.competenceWeight;
              this.performanceEvaluationResult.kpiWeight = performanceEvaluationResult.evaluation.template.kpiWeight;
              performanceEvaluationResult.kpiCategories.forEach((item, key) => {
                let category = new PerformanceEvaluationResultCategory();
                category.id = item.id;
                category.name = item.category.category.name;
                category.color = item.category.category.color;
                category.type = item.category.category.type;
                item.items.forEach((row, index) => {
                  let item = new PerformanceEvaluationResultCategoryItem();
                  item.id = row.id;
                  item.weight = row.item.weight;
                  item.name = row.item.item.name;
                  item.color = row.item.item.color;
                  item.score = row.score || null;
                  item.description = row.item.item.description;
                  item.comment = row.item.item.comment;
                  item.userComment = row.description;
                  category.items.push(item);
                });
                this.performanceEvaluationResult.kpiCategories.push(category);
              });
              performanceEvaluationResult.competenceCategories.forEach((item, key) => {
                let category = new PerformanceEvaluationResultCategory();
                category.name = item.category.category.name;
                category.color = item.category.category.color;
                category.type = item.category.category.type;
                item.items.forEach((row, index) => {
                  let item = new PerformanceEvaluationResultCategoryItem();
                  item.id = row.id;
                  item.weight = row.item.weight;
                  item.name = row.item.item.name;
                  item.color = row.item.item.color;
                  item.score = row.score || null;
                  item.description = row.item.item.description;
                  item.comment = row.item.item.comment;
                  item.userComment = row.description;
                  category.items.push(item);
                });
                this.performanceEvaluationResult.competenceCategories.push(category);
              });
              this.expandedKpiCategories = Array(performanceEvaluationResult.kpiCategories.length).fill(true);
              this.expandedCompetenceCategories = Array(performanceEvaluationResult.competenceCategories.length).fill(
                true
              );
              this.$emit("detail-loaded:success");
            }
          })
          .catch(() => {
            this.$emit("detail-loaded:error");
          })
          .finally(() => {
            this.loading = false;
          });
      },

      validateAndSave() {
        this.$refs.form
          .validate()
          .then(valid => {
            if (valid) {
              this.loading = true;
              this.$api.performanceService
                .updateEvaluationResult(this.performanceEvaluationResult.getDTO())
                .then(({ data }) => {
                  if (!data.error) {
                    this.$emit("success:save");
                    this.$helpers.showNotification("saved_successfully", "success");

                    this.leavePage();
                  } else {
                    if (data.error.code === 762) {
                      this.$helpers.showNotification("evaluation_date_range_error_message");
                    } else this.$helpers.showNotification("registration_failed");
                    this.loading = false;
                  }
                })
                .catch(error => {
                  console.log(error);
                })
                .finally(() => {
                  this.loading = false;
                });
            }
          })
          .catch(e => {
            console.log(e);
            this.loading = false;
          });
      }
    }
  };
</script>

<style scoped></style>

export class PerformanceEvaluationResult {
  constructor() {
    this.id = null;
    this.name = null;
    this.expirationBeginDate = null;
    this.expirationEndDate = null;
    this.validationBeginDate = null;
    this.validationEndDate = null;
    this.scoreSettings = null;
    this.evaluatee = new PerformanceEvaluationResultEvaluatee();
    this.kpiWeight = null;
    this.competenceWeight = null;
    this.kpiCategories = [];
    this.competenceCategories = [];
  }

  getDTO() {
    let result = [];
    for (let x = 0; x < this.kpiCategories.length; x++) {
      let items = this.kpiCategories[x].items;
      for (let y = 0; y < items.length; y++) {
        let item = items[y];
        let dto = new PerformanceEvaluationResultDTO();
        dto.id = item.id;
        dto.score = item.score || 0;
        dto.description = item.userComment;
        result.push(dto);
      }
    }
    for (let x = 0; x < this.competenceCategories.length; x++) {
      let items = this.competenceCategories[x].items;
      for (let y = 0; y < items.length; y++) {
        let item = items[y];
        let dto = new PerformanceEvaluationResultDTO();
        dto.id = item.id;
        dto.score = item.score || 0;
        dto.description = item.userComment;
        result.push(dto);
      }
    }
    return result;
  }
}

class PerformanceEvaluationResultDTO {
  constructor() {
    this.id = null;
    this.score = null;
    this.description = null;
  }
}

export class PerformanceEvaluationResultEvaluatee {
  constructor() {
    this.firstName = "";
    this.lastName = "";
    this.profile = { imageUrl: null };
    this.employment = { branch: { name: null }, jobInfo: { name: null } };
  }
}

export class PerformanceEvaluationResultScoreSettings {
  constructor() {
    this.minScore = null;
    this.maxScore = null;
    this.scoreSettingsDescriptions = { fromScore: null, toScore: null, description: null };
  }
}

export class PerformanceEvaluationResultCategory {
  constructor() {
    this.id = null;
    this.name = null;
    this.color = null;
    this.type = null;
    this.description = null;
    this.items = [];
  }
}

export class PerformanceEvaluationResultCategoryItem {
  constructor() {
    this.id = null;
    this.name = null;
    this.score = null;
    this.weight = null;
    this.description = null;
    this.comment = null;
    this.userComment = null;
  }
}

export default PerformanceEvaluationResult;
